import { MantineProvider } from "@mantine/core";
import "./App.css";
import "@mantine/core/styles.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Home from "./pages/Home";
import ContactUs from "./pages/ContactUs";
import Layout from "./pages/Layout";
import Markets from "./pages/Markets";
import { mantineTheme } from "./assets/styles/mantineTheme";
import NotFound from "./pages/NotFound";
import Careers from "./pages/Careers";
import AboutUsPage from "./pages/AboutUsPage";
import PoultryFarmingService from "./pages/PoultryFarmingService";
import MarketResearchService from "./pages/MarketResearchService";
import RegisteringAndDistributionService from "./pages/RegisteringAndDistributionService";
import VetMedicinesService from "./pages/VetMedicinesService";
import MarketingService from "./pages/MarketingService";
import DistributionService from "./pages/DistributionService";
import ConsultationService from "./pages/ConsultationService";

function App() {
  return (
    <MantineProvider theme={mantineTheme}>
      <>
        <Router>
          <Routes>
            <Route exact element={<Layout />}>
              <Route path="/" exact element={<Home />} />
              <Route path="about-us" exact element={<AboutUsPage />} />
              <Route path="services" exact>
                <Route
                  path=""
                  element={<Navigate to="/services/registering-distribution" />}
                />
                <Route path="poultry" element={<PoultryFarmingService />} />
                <Route
                  path="market-research"
                  element={<MarketResearchService />}
                />
                <Route
                  path="registering-distribution"
                  element={<RegisteringAndDistributionService />}
                />
                <Route path="vet-solutions" element={<VetMedicinesService />} />
                <Route path="marketing" element={<MarketingService />} />
                <Route
                  path="storing-delivery"
                  element={<DistributionService />}
                />
                <Route path="consultation" element={<ConsultationService />} />
              </Route>
              <Route path="markets" exact element={<Markets />} />
              <Route path="careers" exact element={<Careers />} />
              <Route path="contact" exact element={<ContactUs />} />
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
      </>
    </MantineProvider>
  );
}

export default App;
