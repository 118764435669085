import React, { useEffect } from "react";
import { FaChevronRight } from "react-icons/fa6";
import { NavLink } from "react-router-dom";
import featureImg from "../assets/images/feature-1.webp";

function RegisteringAndDistributionService() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="py-[7rem]">
      <div className="flex flex-col justify-center items-center ">
        <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
          <div className="grid sm:grid-cols-2 sm:items-center gap-8">
            <div className="sm:order-2">
              <div className="relative pt-[50%] sm:pt-[100%] rounded-lg">
                <img
                  className="w-full h-full absolute top-0 start-0 object-cover rounded-lg"
                  src={featureImg}
                  alt="signing document"
                />
              </div>
            </div>

            <div className="flex flex-col sm:order-1">
              <h2 className="text-2xl font-bold md:text-3xl lg:text-4xl lg:leading-tight xl:text-5xl xl:leading-tight text-gray-800 ">
                <span className="text-primary ">
                  Getting Products into the market
                </span>
              </h2>
              <p className="mt-3 text-base text-gray-500 ">
                Bridging Gaps, Delivering Essentials
              </p>
              <span className="mt-4 leading-relaxed">
                Cebika is dedicated to the comprehensive management of
                healthcare essentials, from the distribution of high-quality
                medical devices, medications, and makeup products to seamless
                regulatory compliance. Our efforts go beyond mere distribution;
                they ensure enhanced accessibility to vital healthcare
                resources. Successfully distributing medical devices in{" "}
                <p className="inline-flex items-center gap-1.5 py-1 px-3 rounded-md text-xs font-medium bg-gray-200 text-gray-800 ">
                  🇸🇦 Saudi Arabia
                </p>{" "}
                and{" "}
                <p className="inline-flex items-center gap-1.5 py-1 px-3 rounded-md text-xs font-medium bg-gray-200 text-gray-800 ">
                  🇪🇬 Egypt
                </p>
                , we've addressed critical needs where they matter most.
                Simultaneously, we are expanding medication distribution
                networks in{" "}
                <p className="inline-flex items-center gap-1.5 py-1 px-3 rounded-md text-xs font-medium bg-gray-200 text-gray-800 ">
                  🇨🇩 Congo DRC
                </p>{" "}
                , forging pathways to accessible healthcare solutions.
              </span>
            </div>
          </div>
          <div className="mt-10 flex flex-col md:flex-row w-full gap-2 justify-between items-center">
            <div className="w-full"></div>
            <NavLink to="/services/vet-solutions" className="w-full">
              <div className="group transition-all ease-in-out duration-300 cursor-pointer flex flex-col justify-end gap-2 bg-slate-200 p-10 items-end border border-transparent text-base font-medium rounded-md hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-dark">
                <div className="flex items-center text-slate-400 gap-1 group-hover:gap-2">
                  <small className="">Next</small>
                  <FaChevronRight />
                </div>
                <span>Veterinary Solutions</span>
              </div>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegisteringAndDistributionService;
