import React, { useEffect } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa6";
import { NavLink } from "react-router-dom";
import featureOne from "../assets/images/animals/1.jpeg";
import featureTwo from "../assets/images/animals/2.jpg";
import featureThree from "../assets/images/animals/3.jpeg";
import featureFour from "../assets/images/animals/4.jpeg";

function VetMedicinesService() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="py-[7rem]">
      <div className="flex flex-col justify-center items-center ">
        <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
          <div className="grid sm:grid-cols-2 sm:items-center gap-8">
            <div className="flex flex-col sm:order-1 col-span-2">
              <h2 className="text-2xl font-bold md:text-3xl lg:text-4xl lg:leading-tight xl:text-5xl xl:leading-tight text-gray-800">
                <span className="text-primary">
                  BIOTRA: Supplying Essential Veterinary Solutions
                </span>
              </h2>
              <div className="mt-7 flex items-center gap-2 font-bold text-xl p-3 bg-cyan-500 text-white w-fit rounded-full">
                <span>{">"}</span>
                <span>Veterinary Medicines & Feed Additives</span>
              </div>
              <div className="mt-3 flex items-center gap-2 font-bold text-xl p-3 bg-indigo-500 text-white w-fit rounded-full">
                <span>{">"}</span>
                <span>Lifestock Farms</span>
              </div>
            </div>
          </div>

          <div className="mt-7 grid grid-cols-1 md:grid-cols-2 gap-3">
            <div className="col-span-1">
              <p className="text-2xl font-[500]">
                Driven by a team of veterinary with more than 14 years of
                experience, BIOTRA experts delivering innovative and
                professional solutions tailored to the industry’s needs. Our
                goal is to provide integrated, high-quality veterinary products
                and services that benefit customers, suppliers, and employees
                alike.
              </p>

              <hr className="my-7" />

              <section className="mt-7">
                <div className="mb-8">
                  <h2 className="text-2xl font-semibold text-cyan-700 mb-4">
                    Why BIOTRA?
                  </h2>
                  <ul className="space-y-4">
                    <li className="text-gray-600">
                      <span className="font-bold text-gray-800">
                        For Customers:
                      </span>{" "}
                      A broad range of products, expert therapeutic programs,
                      and personalized advice from experienced veterinarians.
                    </li>
                    <li className="text-gray-600">
                      <span className="font-bold text-gray-800">
                        For Suppliers:
                      </span>{" "}
                      A professional, well-organized, and visionary partnership
                      experience.
                    </li>
                    <li className="text-gray-600">
                      <span className="font-bold text-gray-800">
                        For Job Seekers:
                      </span>{" "}
                      A rewarding environment with growth opportunities and
                      competitive compensation.
                    </li>
                  </ul>
                </div>

                <hr className="my-7" />

                <div className="mb-8">
                  <h2 className="text-2xl font-semibold text-cyan-700 mb-4">
                    Our Vision & Mission
                  </h2>
                  <p className="text-gray-600">
                    Our vision is to lead the veterinary industry in Africa and
                    the Gulf by offering complete, integrated solutions. We are
                    committed to professionalism and quality, ensuring mutual
                    success for our stakeholders.
                  </p>
                </div>

                <hr className="my-7" />

                <div className="mb-8">
                  <h2 className="text-2xl font-semibold text-cyan-700 mb-4">
                    Services
                  </h2>
                  <p className="text-gray-600">
                    BIOTRA offers diagnostic tools, pharmaceuticals, therapeutic
                    programs, and expert veterinary consultations tailored to
                    meet market demands.
                  </p>
                </div>
              </section>
            </div>
            <div className="hidden md:block">
              <div className="container mx-auto py-12 px-6">
                <div className="grid grid-cols-4 gap-4">
                  {/* Landscape Image 2 */}
                  <div className="col-span-4 row-span-2">
                    <img
                      src={featureOne}
                      alt="Landscape 2"
                      className="w-full h-full object-cover rounded-lg shadow-lg"
                    />
                  </div>

                  {/* Portrait Image 2 */}
                  <div className="col-span-4 row-span-2">
                    <img
                      src={featureFour}
                      alt="Portrait 2"
                      className="w-full h-full object-cover rounded-lg shadow-lg"
                    />
                  </div>

                  {/* Portrait Image 1 */}
                  <div className="col-span-2 row-span-1">
                    <img
                      src={featureTwo}
                      alt="Portrait 1"
                      className="w-full h-full object-cover rounded-lg shadow-lg"
                    />
                  </div>

                  {/* Landscape Image 1 */}
                  <div className="col-span-2 row-span-1">
                    <img
                      src={featureThree}
                      alt="Landscape 1"
                      className="w-full h-full object-cover rounded-lg shadow-lg"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-10 flex flex-col md:flex-row w-full gap-2 justify-between items-center">
            <NavLink to="/services/registering-distribution" className="w-full">
              <div className="group transition-all ease-in-out duration-300 cursor-pointer flex flex-col justify-end gap-2 bg-slate-200 p-10 items-start border border-transparent text-base font-medium rounded-md hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-dark">
                <div className="flex items-center text-slate-400 gap-1 group-hover:gap-2">
                  <FaChevronLeft />
                  <small className="">Previous</small>
                </div>
                <span>Registering & Distribution</span>
              </div>
            </NavLink>
            <NavLink to="/services/marketing" className="w-full">
              <div className="group transition-all ease-in-out duration-300 cursor-pointer flex flex-col justify-end gap-2 bg-slate-200 p-10 items-end border border-transparent text-base font-medium rounded-md hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-dark">
                <div className="flex items-center text-slate-400 gap-1 group-hover:gap-2">
                  <small className="">Next</small>
                  <FaChevronRight />
                </div>
                <span>Marketing Services</span>
              </div>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VetMedicinesService;
