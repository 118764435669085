import React, { useState } from "react";
import logo from "../../assets/images/cebikaaelogo.png";
import { NavLink, useLocation } from "react-router-dom";
import { Burger, Drawer, Menu } from "@mantine/core";
import { FaChevronDown } from "react-icons/fa";

function FloatingHeader() {
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showServicesMenu, setShowServicesMenu] = useState(false);

  return (
    <>
      <header className="fixed flex flex-wrap md:justify-start md:flex-nowrap z-50 w-full text-sm">
        <nav
          className="mt-6 relative max-w-[85rem] w-full bg-white shadow-md border border-gray-200 rounded-[36px] mx-2 py-5 px-4 md:flex md:items-center md:justify-between md:px-6 lg:px-8 lg:mx-8 xl:mx-auto "
          aria-label="Global"
        >
          <div className="flex items-center justify-between">
            <NavLink
              className="flex-none text-xl font-semibold cursor-pointer"
              to="/"
              aria-label="Brand"
            >
              <img src={logo} alt="logo" className="w-20 h-6" />
            </NavLink>
            <div className="md:hidden">
              <button
                onClick={() => setIsMenuOpen(true)}
                type="button"
                className="hs-collapse-toggle w-8 h-8 flex justify-center items-center text-sm font-semibold rounded-full border border-gray-200 text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none"
                data-hs-collapse="#navbar-collapse-with-animation"
                aria-controls="navbar-collapse-with-animation"
                aria-label="Toggle navigation"
              >
                <svg
                  className="hs-collapse-open:hidden flex-shrink-0 w-4 h-4"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <line x1="3" x2="21" y1="6" y2="6" />
                  <line x1="3" x2="21" y1="12" y2="12" />
                  <line x1="3" x2="21" y1="18" y2="18" />
                </svg>
                <svg
                  className="hs-collapse-open:block hidden flex-shrink-0 w-4 h-4"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M18 6 6 18" />
                  <path d="m6 6 12 12" />
                </svg>
              </button>
            </div>
          </div>
          <div
            id="navbar-collapse-with-animation"
            className="hs-collapse hidden overflow-hidden transition-all duration-300 basis-full grow md:block"
          >
            <div className="flex flex-col gap-y-4 gap-x-0 mt-5 md:flex-row md:items-center md:justify-end md:gap-y-0 md:gap-x-7 md:mt-0 md:ps-7">
              <NavLink
                className={({ isActive }) =>
                  `font-medium cursor-pointer ${
                    isActive ? "text-primary " : "text-slate-800"
                  }`
                }
                to="/"
                aria-current="page"
              >
                Home
              </NavLink>
              <NavLink
                className={({ isActive }) =>
                  `font-medium cursor-pointer ${
                    isActive ? "text-primary " : "text-slate-800"
                  }`
                }
                to="about-us"
              >
                About Us
              </NavLink>
              <Menu
                width={200}
                shadow="md"
                trigger="hover"
                openDelay={100}
                closeDelay={400}
              >
                <Menu.Target>
                  <span
                    style={{
                      cursor: "pointer",
                      fontWeight: "500",
                      color:
                        location.pathname.slice(0, 10) === "/services/"
                          ? "#3b82f6"
                          : "#252525",
                    }}
                  >
                    Services
                  </span>
                </Menu.Target>

                <Menu.Dropdown>
                  <NavLink to="/services/registering-distribution">
                    <Menu.Item>Registering and Distribution</Menu.Item>
                  </NavLink>
                  <NavLink to="/services/vet-solutions">
                    <Menu.Item>Veterinary Solutions</Menu.Item>
                  </NavLink>
                  <Menu.Item>
                    <NavLink to="/services/marketing">
                      Marketing Services
                    </NavLink>
                  </Menu.Item>
                  <NavLink to="/services/market-research">
                    <Menu.Item>Market Research</Menu.Item>
                  </NavLink>
                  <NavLink to="/services/storing-delivery">
                    <Menu.Item>Logistics</Menu.Item>
                  </NavLink>
                  <NavLink to="/services/consultation">
                    <Menu.Item>Consultation</Menu.Item>
                  </NavLink>
                </Menu.Dropdown>
              </Menu>
              <NavLink
                className={({ isActive }) =>
                  `font-medium cursor-pointer ${
                    isActive ? "text-primary " : "text-slate-800"
                  }`
                }
                to="markets"
              >
                Markets
              </NavLink>
              <NavLink
                className={({ isActive }) =>
                  `font-medium cursor-pointer ${
                    isActive ? "text-primary " : "text-slate-800"
                  }`
                }
                to="contact"
              >
                Contact Us
              </NavLink>
            </div>
          </div>
        </nav>
      </header>
      <Drawer
        opened={isMenuOpen}
        size="100%"
        position="top"
        onClose={() => {
          setIsMenuOpen(false);
          setShowServicesMenu(false);
        }}
        withCloseButton={false}
      >
        <Drawer.Body className="h-full">
          <div className="flex flex-col h-full  justify-center items-center text-center gap-y-4 gap-x-0 mt-5 md:flex-row md:items-center md:justify-end md:gap-y-0 md:gap-x-7 md:mt-0 md:ps-7  transition-all ease-in-out duration-500 ">
            <Burger
              opened={isMenuOpen}
              onClick={() => {
                setIsMenuOpen(false);
                setShowServicesMenu(false);
              }}
              className="mb-10"
            />
            <NavLink
              onClick={() => {
                setIsMenuOpen(false);
                setShowServicesMenu(false);
              }}
              className={({ isActive }) =>
                `font-medium cursor-pointer ${
                  isActive ? "text-primary " : " text-slate-900"
                }`
              }
              to="/"
              aria-current="page"
            >
              Home
            </NavLink>
            <NavLink
              onClick={() => {
                setIsMenuOpen(false);
                setShowServicesMenu(false);
              }}
              className={({ isActive }) =>
                `font-medium cursor-pointer ${
                  isActive ? "text-primary " : " text-slate-900"
                }`
              }
              to="about-us"
            >
              About Us
            </NavLink>

            <span
              style={{
                cursor: "pointer",
                fontWeight: "500",
                color:
                  location.pathname.slice(0, 10) === "/services/"
                    ? "#3b82f6"
                    : "black",
              }}
              onClick={() => setShowServicesMenu(!showServicesMenu)}
            >
              Services
              <FaChevronDown
                className={`inline-block ml-1 transition-all ease-in-out duration-500 ${
                  showServicesMenu ? "transform rotate-180" : ""
                }`}
              />
            </span>

            {showServicesMenu ? (
              <div className="border flex flex-col gap-2 px-5 py-5 text-slate-900">
                <NavLink
                  className="cursor-pointer"
                  onClick={() => {
                    setIsMenuOpen(false);
                    setShowServicesMenu(false);
                  }}
                  to="/services/registering-distribution"
                >
                  Registering and Distribution
                </NavLink>
                <NavLink
                  className="cursor-pointer"
                  onClick={() => {
                    setIsMenuOpen(false);
                    setShowServicesMenu(false);
                  }}
                  to="/services/vet-solutions"
                >
                  Veterinary Solutions
                </NavLink>
                <NavLink
                  className="cursor-pointer"
                  onClick={() => {
                    setIsMenuOpen(false);
                    setShowServicesMenu(false);
                  }}
                  to="/services/marketing"
                >
                  Marketing Services
                </NavLink>
                <NavLink
                  className="cursor-pointer"
                  onClick={() => {
                    setIsMenuOpen(false);
                    setShowServicesMenu(false);
                  }}
                  to="/services/market-research"
                >
                  Market Research
                </NavLink>
                <NavLink
                  className="cursor-pointer"
                  onClick={() => {
                    setIsMenuOpen(false);
                    setShowServicesMenu(false);
                  }}
                  to="/services/storing-delivery"
                >
                  Logistics
                </NavLink>
                <NavLink
                  className="cursor-pointer"
                  onClick={() => {
                    setIsMenuOpen(false);
                    setShowServicesMenu(false);
                  }}
                  to="/services/consultation"
                >
                  Consultation
                </NavLink>
              </div>
            ) : null}

            <NavLink
              onClick={() => {
                setIsMenuOpen(false);
                setShowServicesMenu(false);
              }}
              className={({ isActive }) =>
                `font-medium cursor-pointer ${
                  isActive ? "text-primary " : " text-slate-900"
                }`
              }
              to="markets"
            >
              Markets
            </NavLink>

            <NavLink
              onClick={() => setIsMenuOpen(false)}
              className={({ isActive }) =>
                `font-medium cursor-pointer ${
                  isActive ? "text-primary " : " text-slate-900"
                }`
              }
              to="contact"
            >
              Contact Us
            </NavLink>

            <div className="mt-10">
              <hr className="border-gray-300 " />
              <div className="flex items-center justify-between mt-5">
                <div className="text-center">
                  <p className="text-sm font-medium text-gray-600 ">
                    Cebika A.E.
                  </p>
                  <p className="text-sm font-medium text-gray-600 ">
                    All Rights Reserved
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Drawer.Body>
      </Drawer>
    </>
  );
}

export default FloatingHeader;
